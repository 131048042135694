import React, { useRef, useEffect, useState } from "react";
import H3 from "../shared/h3/component";
import styles from "./customer-detail.module.scss";
import LabelWithText from "../shared/label-with-text/component";
import InputText from "../shared/input-text/component";
import { LEAD_DETAILS_TABS, NUMBERS, ERROR_MESSAGE } from "../../utils/app-constants";
import { onNumberKeyPress, showToastMessages, isBikeSource } from "../../utils/helper";
import { validateEmail } from "../../utils/validators/email-validator";
import { AppointmentService } from "../../service";
import ButtonLoader from "../button-loader";
import ClipboardImage from "../images/clipboard.svg";
import copy from 'copy-to-clipboard';
import Button from "../shared/button/component";
import {ERROR_CODES} from '../../service/api-constants';
const LABEL_FIELDS = {
  FULL_NAME: 'Customer Full Name',
  APPOINTMENT_ID: 'Appointment ID',
  PRIMARY_PHONE: 'Primary Phone',
  SECONDARY_PHONE: 'Secondary Phone',
  VIRTUAL_NUMBER: 'Virtual Number',
  PRIMARY_EMAIL: 'Primary Email',
  SECONDARY_EMAIL: 'Secondary Email',
  PERMANENT_ADDRESS: 'Permanent Address',
  PAYMENT_STATUS: 'Payment status',
  BACKUP_VNS: "Backup Virtual Numbers"
};
const UPDATE_KEYS = {
  SECONDARY_PHONE: 'secondaryPhone',
  SECONDARY_EMAIL: 'secondaryEmail',
  ADDRESS: 'customerAddress',
  CUSTOMER_NAME: 'customer_name'
};

const CustomerDetail = ({
  selectedTab,
  data,
}) => {
  const customerDetailRef = useRef();
  const {
    appointmentId,
    name,
    primaryPhone,
    secondaryPhone,
    exophone,
    secondaryEmail,
    address,
    stateId,
    isChangeVnAllowed,
    fallbackVn,
    backupExophone,
    isClickToCall,
  } = data;
  let { primaryEmail } = data;
  const isBike = isBikeSource();
  const [scndryPhone, setScndryPhone] = useState(secondaryPhone || "");
  const [scndryEmail, setScndryEmail] = useState(secondaryEmail || "");
  const [fullName, setFullName] = useState(name || "");
  const [showNameError, setShowNameError] = useState(false);
  const [cstmrAddress, setCstmrAddress] = useState(address || "");
  const [changedKey, setChangedKey] = useState();
  const [initialValue, setInitialValue] = useState("");
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showAddressError, setShowAddressError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [copiedPrimaryEmail, setCopiedPrimaryEmail] = useState(false);
  const [exophoneNumber, SetExophoneNumber] = useState(exophone||'');
  const [allocateOpen, setAllocateOpen] = useState(false);
  const [showBackupVns, setShowBackupVns] = useState(false);
  // If priamry email is contained abtest or dsa string from Seller Service then LMS doesn't display it - LV-1077
  if (primaryEmail.includes("abtest") || primaryEmail.includes("dsa")){
    primaryEmail = "";
  }
  useEffect(() => {
    if (selectedTab && selectedTab === LEAD_DETAILS_TABS.CUSTOMER_DETAIL.key && customerDetailRef && customerDetailRef.current) {
      customerDetailRef.current.scrollIntoView();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (copiedPrimaryEmail) {
      setTimeout(() => {
        setCopiedPrimaryEmail(false);
      }, 3000);
    }
  }, [copiedPrimaryEmail]);

  const onSecondaryPhoneFocus = () => {
    setChangedKey(UPDATE_KEYS.SECONDARY_PHONE);
    setInitialValue(scndryPhone);
  };

  const onSecondaryPhoneChange = (e) => {
    const { target: { value } } = e;
    setScndryPhone(value);
    if (isValidSecondaryPhoneNumber(scndryPhone)){
      setShowPhoneError(false);
    }
    else{
      setShowPhoneError(true);
    }
  };

  const onSecondaryPhoneBlur = () => {
    if (scndryPhone !== initialValue) {
      if (scndryPhone.length !== NUMBERS.TEN) {
        setShowPhoneError(true);
      } else {
        if (isValidSecondaryPhoneNumber(scndryPhone)){
          updateDetails(scndryPhone);
          setShowPhoneError(false);
        }
        else{
          setShowPhoneError(true);
        }
      }
    } else {
      resetValues();
    }
  };

  const isValidSecondaryPhoneNumber = (phoneNumber) => {
    return phoneNumber.startsWith("6") || phoneNumber.startsWith("7") || phoneNumber.startsWith("8") || phoneNumber.startsWith("9") ? true:false;
  };

  const onSecondaryEmailFocus = () => {
    setChangedKey(UPDATE_KEYS.SECONDARY_EMAIL);
    setInitialValue(scndryEmail);
  };

  const onSecondaryEmailChange = (e) => {
    const { target: { value } } = e;
    setScndryEmail(value);
    setShowEmailError(false);
  };

  const onSecondaryEmailBlur = () => {
    if (scndryEmail !== initialValue) {
      if (validateEmail(scndryEmail)) {
        updateDetails(scndryEmail);
        setShowEmailError(false);
      } else {
        setShowEmailError(true);
      }
    } else {
      resetValues();
    }
  };

  const onFullNameChange = (e) => {
    const { target: { value } } = e;
    setFullName(value);
    setShowNameError(false);
  };
  const onfullNameBlur = () => {
    if (fullName !== initialValue) {
      if (!!fullName) {
        updateDetails(fullName);
        setShowNameError(false);
      } else {
        setShowNameError(true);
      }
    } else {
      resetValues();
    }
  };
  const onFullNameFocus = () => {
    setChangedKey(UPDATE_KEYS.CUSTOMER_NAME);
    setInitialValue(fullName);
  };
  const onAddressFocus = () => {
    setChangedKey(UPDATE_KEYS.ADDRESS);
    setInitialValue(cstmrAddress);
  };

  const onAddressChange = (e) => {
    const { target: { value } } = e;
    setCstmrAddress(value);
    setShowAddressError(false);
  };

  const onAddressBlur = () => {
    if (cstmrAddress !== initialValue) {
      if (cstmrAddress && cstmrAddress.trim()) {
        updateDetails(cstmrAddress);
        setShowAddressError(false);
      } else {
        setShowAddressError(true);
      }
    } else {
      resetValues();
    }
  };

  const resetValues = () => {
    setChangedKey();
    setInitialValue("");
    setIsLoading(false);
  };

  const updateDetails = (value) => {
    setIsLoading(true);
    const params = {
      'appointment': appointmentId,
      'field': changedKey,
      'value': value
    };
    AppointmentService.updateAppointment(params)
      .then(resp => {
        const { data: { detail: { message } } } = resp;
        showToastMessages(message);
        resetValues();
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(detail, false);
        resetValues();
      });
  };

  const copyPrimaryEmail = () => {
    copy(primaryEmail, { format: 'text/plain' });
    setCopiedPrimaryEmail(true);
  };

  const onChangeVN = () => {
    const params = {
      'appointmentId': appointmentId,
      'exophone': exophoneNumber
    };
    AppointmentService.postAppointmentReviseVN(params)
      .then(resp => {
        const { data: { detail: { exophone, message } } } = resp;
        SetExophoneNumber(exophone);
        showToastMessages(message);
      })
      .catch(err => {
        const { detail } = err;
        showToastMessages(!!detail?detail.message:'Error: Change VN failed', false);
      });
  };

  const onGenerateNo = () => {
    setIsLoading(true);
    AppointmentService.getVirtualNo(appointmentId).then(response => {
      const { data: { detail: { exophone } } } = response;
      SetExophoneNumber(exophone);
    }).catch(error => {
      const { detail: { message } } = error;
      isBike && fallbackVn && SetExophoneNumber(fallbackVn);
      (!isBike || !fallbackVn) && showToastMessages(message, false);
    });
  };

  const onCallCustomer = () => {
    setIsLoading(true);
    AppointmentService.callCustomer(appointmentId).then(response => {
      showToastMessages(response.detail);
    }).catch(error => {
      const { detail: { message }, status } = error;
      if (status === ERROR_CODES.NOT_ACCESS) {
        showToastMessages(error.detail || message, false);
      }
    }).finally(()=>{
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    });
  };

  const onAllocateOpen = () => {
    setAllocateOpen(true);
  };
  const onAllocateClose = () => {
    setAllocateOpen(false);
  };

  return (
    <div ref={customerDetailRef} className={styles.costumerDetailWrapper}>
      <H3 text={"Customer Details"} />
      <div className={styles.labelWithTextWrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            {/* <LabelWithText
                            label={LABEL_FIELDS.FULL_NAME}
                            text={name}
                        /> */}
            <InputText
              onChange={onFullNameChange}
              value={fullName}
              text={LABEL_FIELDS.FULL_NAME}
              onBlurCallback={onfullNameBlur}
              onFocusCallback={onFullNameFocus}
            />
            {isLoading && changedKey === UPDATE_KEYS.CUSTOMER_NAME && <div className={styles.loader}><ButtonLoader color='#000' /></div>}

            {
              showNameError &&
              <small className={`form-text text-danger`} >{"Required"}</small>
            }
          </div>
          <div className={styles.detailsRightWrapper}>
            <LabelWithText
              label={LABEL_FIELDS.APPOINTMENT_ID}
              text={appointmentId}
            />
          </div>
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            <LabelWithText
              label={LABEL_FIELDS.PRIMARY_PHONE}
              text={primaryPhone}
            />
          </div>
          <div className={styles.detailsRightWrapper}>
            <InputText
              onChange={onSecondaryPhoneChange}
              value={scndryPhone}
              text={LABEL_FIELDS.SECONDARY_PHONE}
              maxLength={NUMBERS.TEN}
              onKeyPress={onNumberKeyPress}
              onBlurCallback={onSecondaryPhoneBlur}
              onFocusCallback={onSecondaryPhoneFocus}
              inputPattern={"[6-9]{1}[0-9]{9}"}
              // required="required"
            />
            {isLoading && changedKey === UPDATE_KEYS.SECONDARY_PHONE && <div className={styles.loader}><ButtonLoader color='#000' /></div>}
            {
              showPhoneError &&
              <small className={`form-text text-danger`} >{ERROR_MESSAGE.OTHER_NUMBER}</small>
            }
          </div>
        </div>
        {!isBike && <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            {isClickToCall ? 
              <div className={styles.changevnWrapper}> 
                <Button 
                  disabled={isLoading}
                  isLoading={isLoading} 
                  ctaText={"CALL CUSTOMER"} 
                  classNames="generateCta"  
                  onClick={() => onCallCustomer()} />
              </div> :
              <div className={styles.changevnWrapper}>
                <div className={styles.changevn}>
                  <LabelWithText
                    label={LABEL_FIELDS.VIRTUAL_NUMBER}
                    text={exophoneNumber}
                  />
                </div>
                {isChangeVnAllowed && <Button ctaText={"Change VN"} classNames="transparentBtn"  onClick={() => onChangeVN()} />}
                {!isChangeVnAllowed && !exophoneNumber && stateId ==="20" && <Button ctaText={"GENERATE NO."} classNames="transparentBtn"  onClick={() => onGenerateNo()} />}
              </div>
            }
          </div>
        </div>}
        {!isClickToCall && <div className={styles.textWrapper}>
          <div className={styles.detailsLeftWrapper}>
            {showBackupVns ? 
              <LabelWithText
                label={LABEL_FIELDS.BACKUP_VNS}
                text={backupExophone}
                isBackupVN={true}
              /> : 
              <Button ctaText={LABEL_FIELDS.BACKUP_VNS} classNames="transparentBtn"  onClick={() => setShowBackupVns(true)} />
            }
          </div>
        </div>}
        <div className={styles.textWrapper} >
          <div className={styles.detailsLeftWrapper}>
            <div className={styles.primaryEmail}>
              <div className={styles.fixemail}>
                <LabelWithText
                  label={LABEL_FIELDS.PRIMARY_EMAIL}
                  text={primaryEmail}
                />
              </div>
              {!copiedPrimaryEmail && !!primaryEmail &&
              <img src={ClipboardImage} className={styles.clipboadImage} alt="Copy" onClick={copyPrimaryEmail} />}
              {copiedPrimaryEmail && <span className={styles.copiedIcon}>Copied!</span>}
            </div>

          </div>
          <div className={styles.detailsRightWrapper}>
            <InputText
              onChange={onSecondaryEmailChange}
              value={scndryEmail.includes("abtest") || scndryEmail.includes("dsa") ? "" : scndryEmail}
              text={LABEL_FIELDS.SECONDARY_EMAIL}
              onBlurCallback={onSecondaryEmailBlur}
              onFocusCallback={onSecondaryEmailFocus}
            />
            {isLoading && changedKey === UPDATE_KEYS.SECONDARY_EMAIL && <div className={styles.loader}><ButtonLoader color='#000' /></div>}
            {
              showEmailError &&
              <small className={`form-text text-danger`} >{ERROR_MESSAGE.EMAIL}</small>
            }
          </div>
        </div>
        <div className={styles.textWrapper}>
          <InputText
            text={LABEL_FIELDS.PERMANENT_ADDRESS}
            value={cstmrAddress}
            onChange={onAddressChange}
            onBlurCallback={onAddressBlur}
            onFocusCallback={onAddressFocus}
          />
          {isLoading && changedKey === UPDATE_KEYS.ADDRESS && <div className={styles.loader}><ButtonLoader color='#000' /></div>}
          {
            showAddressError &&
            <small className={`form-text text-danger`} >{ERROR_MESSAGE.ADDRESS}</small>
          }
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
