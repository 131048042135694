import React, { useState } from "react";
import H2 from "../h2/component";
import styles from "./customer-lead-detail.module.css";
import LeadDetailTabs from "../lead-detail-tabs";
import CustomerDetail from "../customer-detail";
import {
    PAGE_HEADING,
    LEAD_DETAILS_TABS,
    ROLE_KEYS,
} from "../../utils/app-constants";
import LeadAppointmentsDetail from "../lead-appointments-detail";
import LeadCarDetail from "../lead-car-detail";
import LeadC24QuoteDetail from "../lead-c24-Quote-detail";
import DealerDetails from "../dealer-details";
import "react-perfect-scrollbar/dist/css/styles.css";
import { isPllUser, isBikeSource, getLoginData } from "../../utils/helper";
import PriceAcceptanceDetails from "../price-acceptance-details";

const CustomerLeadDetail = ({ data, isSaleOnline }) => {
    const [selectedTab, setSelectedTab] = useState(
        LEAD_DETAILS_TABS.APPOINTMENT_DETAIL.key
    );
    const { state_name, appointmentId, isInventoryBuying } = data;
    const isPll = isPllUser();
    const isBike = isBikeSource();
    const { originalRole = "", isHierarchy } = getLoginData();
    const isCJMRole =
    [ROLE_KEYS.CJM, ROLE_KEYS.A2I_HEAD, ROLE_KEYS.FOOTFALL_MANAGER].includes(
        originalRole
    ) && isHierarchy;
    const isC24QuoteShared = () => {
        if (!!data.is_shared_c24_quote) {
            if (Number(data.is_shared_c24_quote) === 0) return false;
            else return true;
        } else return false;
    };
    const customerDetails = {
        appointmentId: data.appointmentId,
        name: data.customerName,
        primaryPhone: data.customerPhone,
        secondaryPhone: data.secondaryPhone,
        exophone: data.exophone,
        primaryEmail: data.customer_email,
        secondaryEmail: data.secondaryEmail,
        address: data.customer_address,
        paymentStatus: data.paymentStatus,
        stateId: data.state_id,
        stateName: data.state_name,
        rescheduleUrl: data.rescheduleUrl,
        isChangeVnAllowed: !!data.isChangeVnAllowed
            ? data.isChangeVnAllowed
            : false,
        fallbackVn: !!data.fallbackVn ? data.fallbackVn : "",
        generateVnFlag: !!data.generateVnFlag ? data.generateVnFlag : false,
        backupExophone: data.backupExophone || "",
        isClickToCall: data?.isClickToCall,
    };
    const carDetails = {
        appointmentId: data.appointmentId,
        carId: data.car_id,
        store: data.store_name,
        carJockey: data.evaluator_name,
        stateName: data.state_name,
        preStateName: data.pre_state_name || "",
        paName: data.paName || "",
        paPhone: data.paPhone || "",
        insPectionDate: data.current_ins_date,
        inspReportUrl: data.inspReportUrl,
    };
    const c24QuoteDetails = {
        appointmentId: data.appointmentId,
        minWebQuote: data.min_quoted_price,
        maxWebQuote: data.max_quoted_price,
        minUpdatedWebQuote: data.min_updated_quoted_price,
        maxUpdatedWebQuote: data.max_updated_quoted_price,
        suggestedC24Quote: data.suggested_c24_quote,
        finalC24Quote: data.c24Quote,
        stateName: data.state_name,
        isC24QuoteShared: isC24QuoteShared(),
        offerData: data.offerData,
        pllLimitC24Quote: data.pllLimitC24Quote,
        negativeMargin: data.negativeMargin,
        minC24Quote: data.minC24Quote,
        maxC24Quote: data.maxC24Quote,
        marginQuotaCtaFlag: data.marginQuotaCtaFlag,
        minC24QuoteFlag: data.minC24QuoteFlag,
        soOfferPrice: data.sellerOfferPrice,
        maxSoOfferPrice: data.maxSoOfferPrice,
        diyPllLead: data.diyPllLead,
		isDirectOcb: data?.isDirectOcb,
    };
    const appointmentDetails = {
        appointmentId: data?.appointmentId,
        deal_created_date: !!data.deal_created_date
            ? data.deal_created_date
            : "2020-09-30 12:12:12",
        original_appt_date: !!data.original_appt_date
            ? data.original_appt_date
            : "2020-09-30 12:12:12",
        current_appt_date: !!data.current_appt_date
            ? data.current_appt_date
            : "2020-09-30 12:12:12",
        lost_reason: !!data.lost_reason ? data.lost_reason : "",
        isMonetized: data?.isMonetized,
        isPRAutoAllowed: data?.isPRAutoAllowed,
        appointmentKey: data?.appointmentKey,
    };
    const priceAcceptance = {
        price_accepted: !!data.sellOnlineAcceptance ? data.sellOnlineAcceptance : 0,
        reason_for_non_acceptance: !!data.sellOnlineReason
            ? data.sellOnlineReason
            : "",
        CEP: !!data.sellOnlineCep ? data.sellOnlineCep : "",
    };
    return (
        <div className={styles.leadDetailsWrapper}>
            <div className={styles.leadDetailsTitle}>
                <H2 text={PAGE_HEADING.LEAD_DETAILS} />
                {/* <Button classNames="blueButton" ctaText={BUTTONS_CTA_TEXT.SAVE} /> */}
            </div>
            <LeadDetailTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                state_name={state_name}
                isPll={isPll}
                isC24QuoteShared={isC24QuoteShared()}
                isCJMRole={isCJMRole}
            />
            <div className={styles.scrollbarChild}>
                <div className={styles.scrollWrapper}>
                    <LeadAppointmentsDetail
                        selectedTab={selectedTab}
                        data={appointmentDetails}
                    />
                    <CustomerDetail
                        selectedTab={selectedTab}
                        data={customerDetails}
                        isSaleOnline={isSaleOnline}
                    />
                    {!!state_name &&
            state_name.toLowerCase() === "in-negotiation" &&
            !isPll &&
            isC24QuoteShared() && (
                        <DealerDetails
                            selectedTab={selectedTab}
                            appointmentId={appointmentId}
                        />
                    )}
                    <LeadCarDetail selectedTab={selectedTab} data={carDetails} />
                    {!isCJMRole && (
                        <LeadC24QuoteDetail
                            selectedTab={selectedTab}
                            data={c24QuoteDetails}
                            isInventoryBuying={isInventoryBuying}
                            isSaleOnline={isSaleOnline}
                        />
                    )}
                    {isBike && (
                        <PriceAcceptanceDetails
                            selectedTab={selectedTab}
                            data={priceAcceptance}
                        />
                    )}
                    {/* <LeadPaymentRcDetail /> */}
                </div>
            </div>
        </div>
    );
};

export default CustomerLeadDetail;
