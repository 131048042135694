import { ROLE_KEYS } from "../../utils/app-constants";

export const USER_TYPE = {
  ZM: "ZM",
  RA: "RA"
};

export const EMBED_URL = {
  [USER_TYPE.ZM]: "https://public.domo.com/embed/pages",
  [USER_TYPE.RA]: "https://public.domo.com/cards"
};

export const PAGE_ACCESS_ROLES = [
  ROLE_KEYS.RA_TRA,
  ROLE_KEYS.PLL_RA,
  ROLE_KEYS.TM,
  ROLE_KEYS.PLL_RM,
  ROLE_KEYS.PLL,
  ROLE_KEYS.HI_RA_TRA,
  ROLE_KEYS.FRANCHISE_RA,
  ROLE_KEYS.RM_NEW,
  ROLE_KEYS.RM,
  ROLE_KEYS.TM_NEW,
  ROLE_KEYS.CITY_HEADS
];

export const CALL_LOGS_ACCESS_ROLES = [
  ROLE_KEYS.RA_TRA,
  ROLE_KEYS.PLL_RA,
  ROLE_KEYS.PLL,
  ROLE_KEYS.HI_RA_TRA,
  ROLE_KEYS.FRANCHISE_RA
];

export const REPORT_TYPE = {
  MISSED_CALL: "MISSED_CALL",
  CALL_LOG: "CALL_LOG"
};
