import React, { useState } from "react";
import CallLogRecord from "../../components/shared/images/recording.svg";
import styles from "./call-log-recording.module.scss";
import { redirectToUrl, showToastMessages } from "../../utils/helper";
import { AppointmentService } from "../../service";

const CallLogRecording = ({ recordingUrl, callSid, callType }) => {

  const [rcrdngUrl, setRcrdngUrl] = useState(recordingUrl || "");

  const getUrl = () => {
    if (callSid) {
      AppointmentService.getRecordingUrl(callSid)
        .then(resp => {
          const { data: { detail: { recordingUrl } } } = resp;
          setRcrdngUrl(recordingUrl || "");
        })
        .catch(err => {
          const { detail: { message } } = err;
          showToastMessages(message, false);
        });
    }
  };

  return (
    <div className={styles.callLogRecording}>
      {rcrdngUrl && <img src={CallLogRecord} alt="" onClick={() => redirectToUrl(rcrdngUrl)} />}
      {!rcrdngUrl && callType && callType.trim().toLowerCase() === 'completed' && <p className={styles.textRecording} onClick={getUrl}>Get Recording</p>}
    </div>
  );
};

export default CallLogRecording;
