/* eslint-disable import/no-anonymous-default-export */
// import queryString from "querystring";
import { API_ENDPOINTS } from "./api-constants";
import { NUMBERS, LOCAL_STORAGE_KEYS, LEAD_TYPE } from "../utils/app-constants";
import { getFromLocalStorage, isPllUser, getAllocateIsPllString, getLoginData } from "../utils/helper";
// import { getMake } from "../utils/mocApi";

export default (api) => {

  const getStoreOrUidQuery = () => {
    let param = "";
    const leadType = getFromLocalStorage(LOCAL_STORAGE_KEYS.LEADS);
    let isPll = isPllUser();
    if (isPll) {
      param = `&${LOCAL_STORAGE_KEYS.PLL_USER}=${isPll}`;
    }
    if (leadType === LEAD_TYPE.INDIVIDUAL) {
      const uid = getFromLocalStorage(LOCAL_STORAGE_KEYS.INDIVIDUAL_ID);
      return `${param}&uid=${uid}`;
    } else {
      const selectedStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES);
      return `${param}${selectedStores ? `&stores=${selectedStores}` : ``}`;
    }
  };

  const getPllUserParam = () => {
    let isPll = isPllUser();
    if (isPll) {
      return `&${LOCAL_STORAGE_KEYS.PLL_USER}=${isPll}`;
    } else {
      return '';
    }
  };

    const getAppointments = (props) => {
        const {type, page, filterQuery='', searchQuery='', hisFilterQuery, zoneQuery, sortFilterQuery, 
            headerRowFilterQuery, paymentProcessingFilterQuery, leadType, prStatusFilterQuery,raFilterQuery, leadMon} = props;
        const storeOrUid = getStoreOrUidQuery();
        return api.get(`/${type}?start=${page}&limit=${NUMBERS.TEN}${leadMon ? `&leadMon=${leadMon}` : ''}${filterQuery ? filterQuery : ''}${storeOrUid}${searchQuery ? `&query=${searchQuery}` : ''}${hisFilterQuery ? `${hisFilterQuery}` : ''}${zoneQuery ? `${zoneQuery}` : ''}${sortFilterQuery ? `${sortFilterQuery}` : ''}${headerRowFilterQuery ? `${headerRowFilterQuery}` : ''}${Array.isArray(raFilterQuery) ? `&uid=${raFilterQuery.length > NUMBERS.ZERO ? raFilterQuery : '0'}`:''}${paymentProcessingFilterQuery ? `${paymentProcessingFilterQuery}` : ''}${leadType? `&leadType=${leadType}`:''}${prStatusFilterQuery? `&prStatus=${prStatusFilterQuery}`:''}`);
    };

  const getAppointmentDetail = (type, appointmentId) => {
    return api.get(`/${type}/${appointmentId}`);
  };

  const getAppointmentsReview = (page) => {
    return api.get(`${API_ENDPOINTS.INSPECTION_REVIEW}?start=${page}&limit=${NUMBERS.TEN}`);
  };

  const getLostLeads = (page, lostFrom, searchQuery) => {
    const storeOrUid = getStoreOrUidQuery();
    return api.get(`/${API_ENDPOINTS.DEAL_LOST}?start=${page}&limit=${NUMBERS.TWENTY}${storeOrUid}${searchQuery ? `&query=${searchQuery}` : ''}${lostFrom ? `&lostFrom=${lostFrom}` : ''}`);
  };

  const getVirtualNo = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.VIRTUAL_NUMBER}/${appointmentId}`);
  };

  const getNegotiations = (page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, missedCallQuery) => {
    const storeOrUid = getStoreOrUidQuery();
    let negoParams = `?start=${page}&limit=${NUMBERS.TEN}${missedCallQuery?missedCallQuery:''}${filterQuery ? filterQuery : ''}${storeOrUid}${searchQuery ? `&query=${searchQuery}` : ''}${isC2C ? `&isC2c=${isC2C}` : ''}${range ? `&fromDate=${range.from}&toDate=${range.to}` : ''}${lostLeadQuery ? `&lostlead=${lostLeadQuery}` : ''}${pllSideBarFilter ? `${pllSideBarFilter}` : ''}${retailSideBarFilter ? `${retailSideBarFilter}` : ''}`;
    return api.get(`${API_ENDPOINTS.NEGOTIATIONS}${negoParams}`);
  };

  const getDiyNegotiations = (page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer=0, missedCallQuery) => {
    const storeOrUid = getStoreOrUidQuery();
    const diyOrpending = `diy=1&pendingAt=${!!withCustomer ? 1 : 0}`;
    return api.get(`${API_ENDPOINTS.NEGOTIATIONS}?${diyOrpending}&start=${page}&limit=${NUMBERS.TEN}${missedCallQuery?missedCallQuery:''}${filterQuery ? filterQuery : ''}${storeOrUid}${searchQuery ? `&query=${searchQuery}` : ''}${isC2C ? `&isC2c=${isC2C}` : ''}${range ? `&fromDate=${range.from}&toDate=${range.to}` : ''}${lostLeadQuery ? `&lostlead=${lostLeadQuery}` : ''}${pllSideBarFilter ? `${pllSideBarFilter}` : ''}${retailSideBarFilter ? `${retailSideBarFilter}` : ''}`);
  };

  const getRTNegotiations = (page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer=0, missedCallQuery) => {
    const storeOrUid = getStoreOrUidQuery();
	const diyOrpending = `pendingAt=${!!withCustomer ? 1 : 0}`;
    return api.get(`${API_ENDPOINTS.RT_NEGOTIATIONS}?${diyOrpending}&start=${page}&limit=${NUMBERS.TEN}${missedCallQuery?missedCallQuery:''}${filterQuery ? filterQuery : ''}${storeOrUid}${searchQuery ? `&query=${searchQuery}` : ''}${isC2C ? `&isC2c=${isC2C}` : ''}${range ? `&fromDate=${range.from}&toDate=${range.to}` : ''}${lostLeadQuery ? `&lostlead=${lostLeadQuery}` : ''}${pllSideBarFilter ? `${pllSideBarFilter}` : ''}${retailSideBarFilter ? `${retailSideBarFilter}` : ''}`);
  };

  const getRTDIYNegotiations = (page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer=0, missedCallQuery) => {
    const storeOrUid = getStoreOrUidQuery();
    const diyOrpending = `diy=1&pendingAt=${!!withCustomer ? 1 : 0}`;
    return api.get(`${API_ENDPOINTS.RT_NEGOTIATIONS}?${diyOrpending}&start=${page}&limit=${NUMBERS.TEN}${missedCallQuery?missedCallQuery:''}${filterQuery ? filterQuery : ''}${storeOrUid}${searchQuery ? `&query=${searchQuery}` : ''}${isC2C ? `&isC2c=${isC2C}` : ''}${range ? `&fromDate=${range.from}&toDate=${range.to}` : ''}${lostLeadQuery ? `&lostlead=${lostLeadQuery}` : ''}${pllSideBarFilter ? `${pllSideBarFilter}` : ''}${retailSideBarFilter ? `${retailSideBarFilter}` : ''}`);
  };

  const getCarData = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.CAR_DATA}?appointmentId=${appointmentId}`);
  };

  const getCheckInStatus = (params) => {
    return api.post(`${API_ENDPOINTS.CHECK_IN_STATUS}`, params);
  };

  const checkInAppointment = (params) => {
    return api.post(`${API_ENDPOINTS.POST_CHECK_IN}`, params);
  };

  const updateSuggestedQuote = (params) => {
    return api.post(`${API_ENDPOINTS.UPDATE_SUGGESTED_QUOTE}`, params);
  };
  const acceptOcbQuote = (params) => {
    return api.post(`${API_ENDPOINTS.ACCEPT_OCB_QUOTE}`, params);
  };
  const getFollowUpDispositions = (type) => {
    return api.get(`${API_ENDPOINTS.FOLLOW_UP_DISPOSITION}/${type}`);
  };

  const postFollowUp = (params) => {
    return api.post(`${API_ENDPOINTS.FOLLOW_UP}`, params);
  };

  const getFollowUpHistory = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.FOLLOW_UP}/${appointmentId}`);
  };

  const getCancellationReasons = (stateId = "") => {
    return api.get(`${API_ENDPOINTS.CHECK_IN_DENY}?state=${stateId}`);
  };

  const denyCheckIn = (params) => {
    return api.post(`${API_ENDPOINTS.CHECK_IN_DENY}`, params);
  };

  const getAppointmentCounts = () => {
    const storeOrUid = getStoreOrUidQuery().replace('&', '?');
    return api.get(`${API_ENDPOINTS.APPOINTMENT_COUNTS}${storeOrUid}`);
  };

  const getInspectionReport = (carId) => {
    return api.get(`${API_ENDPOINTS.INSPECTION_REPORT}/${carId}`);
  };

  const updateCustomerExpectedPrice = (params) => {
    return api.post(`${API_ENDPOINTS.CUSTOMER_EXPECTED_PRICE}`, params);
  };

  const preCheck = (payload) => {
    return api.post(`${API_ENDPOINTS.PRE_CHECK}`, payload);
  };

  const paiRequest = (payload) => {
    return api.post(`${API_ENDPOINTS.PAI_REQUEST}`, payload);
  };

  const sendOtp = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_OTP}`, params);
  };

  const verifyOtp = (params) => {
    return api.post(`${API_ENDPOINTS.VERIFY_OTP}`, params);
  };

  const globalSearch = (query) => {
    const pllParam = getPllUserParam();
    if (!!query) {
      query = query.trim();
    }
    return api.get(`${API_ENDPOINTS.GLOBAL_SEARCH}?query=${query}${pllParam}`);
  };

  const getAppointmentStateJourney = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.APPOINTMENT_JOURNEY}/${appointmentId}`);
  };

  const getLeadDetails = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.APPOINTMENT_DETAILS}/${appointmentId}`);
  };

  const getCallLogs = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.CALL_LOGS}/${appointmentId}`);
  };

  const updateAppointment = (params) => {
    return api.post(`${API_ENDPOINTS.UPDATE_APPOINTMENT}`, params);
  };

  const getRecordingUrl = (callSid) => {
    return api.get(`${API_ENDPOINTS.RECORDING_URL}/${callSid}`);
  };

  const getRetailAssociates = (storeId) => {
    return api.get(`${API_ENDPOINTS.RETAIL_ASSOCIATES}/${storeId}`);
  };

  const getCheckInLeads = (page, query) => {
    return api.get(`/${API_ENDPOINTS.CHECK_IN_LEADS}?start=${page}&limit=${NUMBERS.TWENTY}&query=${query}`);
  };

  const getStoreList = (countPllLead) => {
	const pllString = getAllocateIsPllString();
    return api.get(`/${API_ENDPOINTS.STORE_LIST}${countPllLead ? `?countPllLead=1${pllString ? `&${pllString}` : ''}` : `${pllString ? `?${pllString}` : ''}`}`);
  };

  const getStoreAssociateList = (query) => {
    const pllParam = getPllUserParam();
	getAllocateIsPllString();
    return api.get(`${API_ENDPOINTS.STORE_ASSOCIATE_LIST}?search=${query}${pllParam}`);
  };

    const getStoreRAList = (storeId, appointmentId) => {
		const isPll = isPllUser();
        return api.get(`${API_ENDPOINTS.STORE_ASSOCIATE_LIST}/${storeId}${isPll ? `?isPll=1&appointmentId=${appointmentId}` : `?appointmentId=${appointmentId}`}`);
    };

    const getStoreRaLeadMonList = () => {
        return api.get(`${API_ENDPOINTS.RETAIL_ASSOCIATES}?leadMon=1`);
    };

  const getDepreciationData = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.DEPRECIATION_DATA}/${appointmentId}`);
  };

  const getPllRaAppointmentList = (id, storeId, gsAssuredParam) => {
    return api.get(`${API_ENDPOINTS.RA_APPOINTMENT_LIST}${id ? `?pllAssociate=${id}` : ''}${storeId ? `?storeId=${storeId}` : ''}${gsAssuredParam}`);
  };
  const getPllAppointmentList = (storeIds, gsAssuredParam, page, limit) => {
	return api.get(`${API_ENDPOINTS.RA_APPOINTMENT_LIST}?start=${page}&limit=${limit}${storeIds ? `&storeId=${storeIds}` : ''}${gsAssuredParam}`);
  };
  const allocateAppointment = (params) => {
	return api.post(`${API_ENDPOINTS.ALLOCATE_APPOINTMENT}`, params);
  };

  const getPllRaList = (storeId, cityName) => {
	const pllString = getAllocateIsPllString();
	const separator = pllString ? '&' : '?';
	return api.get(`${API_ENDPOINTS.PLL_ASSOCIATE_LIST}${pllString ? `?${pllString}`: ''}${storeId ? `${separator}storeId=${storeId}` : ''}${cityName ? `${separator}cityName=${cityName}` : ''}`);
  };
  const raiseOcbData = (payload) => {
    return api.post(`${API_ENDPOINTS.RAISE_OCB_DATA}`, payload);
  };
  const updateOcbData = (payload) => {
    return api.post(`${API_ENDPOINTS.RAISE_OCB_DATA}`, payload);
  };

  const getEvaluatorTokens = (storeId) => {
    return api.get(`${API_ENDPOINTS.EVALUATOR_TOKENS}/${storeId}`);
  };
  const getRetailView = (storeId) => {
    return api.get(`${API_ENDPOINTS.RETAIL_VIEW}/${storeId}`);
  };
  const getEvaluatorView = (storeId) => {
    return api.get(`${API_ENDPOINTS.EVALUATOR_VIEW}/${storeId}`);
  };
  const getmake = () => {
    //return getMake();
    return api.get(`${API_ENDPOINTS.FETCH_MAKE}`);
  };
  const getModel = (make) => {
    //return getMake();
    return api.get(`${API_ENDPOINTS.FETCH_MODEL}?make_id=${make}`);
  };

  const getYear = (model) => {
    //return getMake();
    return api.get(`${API_ENDPOINTS.FETCH_YEAR}?model_id=${model}`);
  };

  const getVariant = (model, year) => {
    //return getMake();
    return api.get(`${API_ENDPOINTS.FETCH_VARIANT}?model_id=${model}&year=${year}`);
  };
  const getState = () => {
    //return getMake();
    return api.get(`${API_ENDPOINTS.FETCH_STATE}?reg_state=1`);
  };

  const getOdometer = () => {
    //return getMake();
    return api.get(`${API_ENDPOINTS.FETCH_ODOMETER}`);
  };

  const getAwareness = () => {
    return api.get(`${API_ENDPOINTS.FETCH_AWARENESS}`);
  };

  const getCity = () => {
    return api.get(`${API_ENDPOINTS.FETCH_CITY}`);
  };

  const getBranch = (cityId) => {
    return api.get(`${API_ENDPOINTS.FETCH_BRANCH}?city=${cityId}`);
  };

  const getSlots = (branch) => {
    return api.get(`${API_ENDPOINTS.FETCH_SLOTS}?location_id=${branch}`);
  };

  const bookAppointment = (params) => {
    return api.post(`${API_ENDPOINTS.BOOK_APPOINTMENT}`, params);
  };
  const marketingQuestions = () => {
    return api.get(`${API_ENDPOINTS.MARKEETING_QUEESTION}`);
  };
  const submitMarketingQuestions = (params) => {
    return api.post(`${API_ENDPOINTS.MARKEETING_QUEESTION}`, params);
  };
  const getOffer = () => {
    return api.get(`${API_ENDPOINTS.OFFER}`);
  };
  const LostLeadQuestions = () => {
    // return api.get(`${API_ENDPOINTS.MARKEETING_QUEESTION}`);
    return api.get(`${API_ENDPOINTS.LOST_LEAD_QUEESTION}?state_id=17`);
  };
  const submitLostLead = (params) => {
    return api.post(`${API_ENDPOINTS.DEAL_LOST}`, params);
  };
  const getSimilarCarsDetails = (appointmentId, page) => {
    return api.get(`${API_ENDPOINTS.SIMILAR_CAR_DETAILS}?query=${appointmentId}&start=${page}&limit=${NUMBERS.TWENTY}`);
  };
  const getCustomerCharges = (appointmentId, c24Quote) => {
    if (c24Quote) return api.get(`${API_ENDPOINTS.CUSTOMER_CHARGES}?appointmentId=${appointmentId}&c24Quote=${c24Quote}`);
    else return api.get(`${API_ENDPOINTS.CUSTOMER_CHARGES}?appointmentId=${appointmentId}`);
  };
  const getCustomerOfferDetails = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.CUSTOMER_OFFER_DETAIL}/${appointmentId}`);
  };
  const getSimilarTransactedCars = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.SIMILAR_TRANSCATED_CARS}/${appointmentId}`);
  };
  const getValidateDashboard = (storeId, uid) => {
    return api.get(`${API_ENDPOINTS.VALIDATE_DASHBOARD}?storeId=${storeId}&uid=${uid}`);
  };
  const verifyWaitTimeOtp = (param) => {
    return api.post(`${API_ENDPOINTS.VERIFY_WAIT_TIME_OTP}`, param);
  };
  const sendWaitTimeOtp = (param) => {
    return api.post(`${API_ENDPOINTS.SEND_WAIT_TIME_OTP}`, param);
  };
  const getC2CBoundyValues = (param) => {
    return api.post(`${API_ENDPOINTS.C2C_BOUNDRY_VALUES}`, param);
  };
  const c2cList = (param) => {
    return api.post(`${API_ENDPOINTS.C2C_LIST}`, param);
  };
  const MarkAppointmentStar = (param) => {
    return api.post(`${API_ENDPOINTS.APPOINTMENT_MARK_STAR}`, param);
  };
  const GetOrderDetail = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.ORDER_DETAIL}/${appointmentId}`);
  };
  const getHomeInspectionZones = (storeId) => {
    return api.get(`${API_ENDPOINTS.HOME_INSPECTION_ZONE}?storeId=${storeId}`);
  };
  const getEvaluatorList = (storeId) => {
    return api.get(`${API_ENDPOINTS.EVALUATOR_LIST}?storeId=${storeId}`);
  };
  const assignEvaluator = (params) => {
    return api.post(`${API_ENDPOINTS.ASSIGN_EVALUATOR}`, params);
  };
  const assignBikeEvaluator = (params) => {
    return api.post(`${API_ENDPOINTS.ASSIGN_BIKE_EVALUATOR}`, params);
  };
  const scheduleRI = (params) => {
    return api.post(`${API_ENDPOINTS.HOME_INSPECTION}`, params);
  };
  const getOwnerShipType = () => {
    return api.get(`${API_ENDPOINTS.TOKEN_ELITE}`);
  };
  const postTokenElite = (params) => {
    return api.post(`${API_ENDPOINTS.TOKEN_ELITE}`, params);
  };
  const getDealerDetail = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.DEALER_DETAIL}?appointmentId=${appointmentId}`);
  };
  const connectToDealer = (params) => {
    return api.post(`${API_ENDPOINTS.DEALER_CONNECT}`, params);
  };
  const getAppointedCjDetail = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.APPOINTED_CJ_DETAIL}?appointment=${appointmentId}`);
  };
  const tokenEliteABTest = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.TOKEN_ELITE_AB_TEST}/${appointmentId}`);
  };
  const postDepReportViewedInfo = (param) => {
    return api.post(`${API_ENDPOINTS.DEPRECIATION_DATA}`, param);
  };
  const sendDepriciationReport = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_DEP_REPORT}`, params);
  };
  const getRaList = () => {
    const selectedStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES);
    const qsParam = selectedStores ? `?stores=${selectedStores}` : '';
    return api.get(`${API_ENDPOINTS.STORE_ASSOCIATE_LIST}${qsParam}`);
  };
  const getCjAllocationStatus = (storeId) => {
    return api.get(`/${API_ENDPOINTS.CJ_ALLOCATION_STATUS}?storeId=${storeId}`);
  };
  const getTimeSlot = () => {
    return api.get(`/${API_ENDPOINTS.TIME_SLOT}`);
  };
  const getEvaluatorLeadCountBasedOnAppointment = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.EVALUATOR_LEAD_COUNT}?appointmentId=${appointmentId}`);
  };
  const getEvaluatorLeadCountBasedOnStore = (searchQuery) => {
    return api.get(`${API_ENDPOINTS.EVALUATOR_LEAD_COUNT}${searchQuery}`);
  };
  const getChangeLogs = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.CHANGE_LOGS}/${appointmentId}`);
  };
  const sendDepReportFeedBack = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_DEP_REPORT_FEEDBACK}`, params);
  };
  const updateProfile = (params) => {
    return api.post(`${API_ENDPOINTS.UPDATE_PROFILE}`, params);
  };
  const getLeadWithPhone = (storeId) => {
    return api.get(`${API_ENDPOINTS.LEAD_DATA_WITH_PHONE}?storeId=${storeId}`);
  };
  const storeCheckIn = (params) => {
    return api.post(`${API_ENDPOINTS.STORE_CHECKIN}`, params);
  };
  const submitReviewInspection = (params) => {
    return api.post(`${API_ENDPOINTS.INSPECTION_REVIEW}`, params);
  };
  const sendListCarOtp = (params) => {
    return api.post(`${API_ENDPOINTS.LIST_CAR_OTP}`, params);
  };
  const sendInspectionReport = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_INSPECTION_REPORT}`, params);
  };
  const getAppointmentDocumentStatusDetails = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.DOCUMENT_STATUS_DETAILS}?appointmentId=${appointmentId}`);
  };
  const postAppointmentBuyNowPrice = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_APPOINTMENT_BUY_NOW_PRICE}`, params);
  };
  const postStartAuction = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_AUCTION_START}`, params);
  };
  const postAppointmentReviseVN = (params) => {
    return api.post(`${API_ENDPOINTS.REVISE_APPOINTMENT_VN}`, params);
  };
  const getDashboardRAIncentive = (raEmail) => {
    return api.get(`${API_ENDPOINTS.DASHBOARD_RA_INCENTIVE}?raEmail=${raEmail}`);
  };
  const getAppointmentAnnouncement = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.GET_APPOINTMENT_ANNOUNCEMENT}?appointmentId=${appointmentId}`);
  };
  const postAppointmentAnnouncement = (params) => {
    return api.post(`${API_ENDPOINTS.PUSH_APPOINTMENT_ANNOUNCEMENT}`, params);
  };
  const getAdminRAId = () => {
    return api.get(`${API_ENDPOINTS.ADMIN_API_USER}`);
  };
  const postResendPickupOTP = (params) => {
    return api.post(`${API_ENDPOINTS.RESEND_PICKUP_OTP}`, params);
  };
  const getFetchLocation = (lat, lng, type='') => {
    let url = `${API_ENDPOINTS.RESCHDULE_APPOINTMENT_FETCH_LOCATION}?lat=${lat}&lng=${lng}`;
    if (!!type) {
      url = `${url}&type=${type}`;
    }
    return api.get(url);
  };
  const postRescheduleAppointment = (params) => {
    return api.post(`${API_ENDPOINTS.POST_RESCHDULE_APPOINTMENT_DATA}`, params);
  };
  const getInvSimilarCars = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.SIMILAR_CAR_INV}?appointmentId=${appointmentId}`);
  };
  const sellerReviews = () => {
    return api.get(`${API_ENDPOINTS.SELLER_REVIEW}`);
  };
  const getPrEnable = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.GET_PR_ENABLE}/${appointmentId}`);
  };
  const getPrBankList = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.GET_PR_BANK_LIST}?appointmentId=${appointmentId}`);
  };
  const getPrVahanCheck = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.GET_PR_VAHAN_CHECK}/${appointmentId}`);
  };
  const getPrKyc = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.GET_PR_KYC}/${appointmentId}`);
  };
  const savePrKyc = (params) => {
    return api.post(`${API_ENDPOINTS.PR_KYC}`, params);
  };
  const canclePrKyc = (params) => {
    return api.post(`${API_ENDPOINTS.PR_KYC}`, params);
  };
  const prResendPennySms = (appointmentId) => {
    return api.post(`${API_ENDPOINTS.PR_PENNY_SMS}`, appointmentId);
  };
  const prResendChargesConsent = (appointmentId) => {
    return api.post(`${API_ENDPOINTS.PR_PENNY_CHARGES_SMS}`, appointmentId);
  };
  const prDocumentView = (appointmentId, params) => {
    return api.get(`${API_ENDPOINTS.PR_DOC_VIEW}?appointmentId=${appointmentId}&docCode=${params}`);
  };
  const prInitiateToken = (params) => {
    return api.post(`${API_ENDPOINTS.GET_PR_INITIATE_TOKEN}`, params);
  };
  const getBikeInitiateTokenPayment = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.BIKE_INITIATE_TOKEN_PAYMENT}/${appointmentId}`);
  };
  const getBikeInitiatePRToken = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.BIKE_INITIATE_PR_TOKEN}/${appointmentId}`);
  };
  const postBikeInitiateTokenPayment = (params) => {
    return api.post(`${API_ENDPOINTS.BIKE_INITIATE_TOKEN_PAYMENT}`, params, { headers: { 'Content-Type': 'multipart/form-data' } });
  };
  const saveCepPrice = (params) => {
    return api.post(`${API_ENDPOINTS.SAVE_CEP_PRICE}`, params);
  };
  const getAppointmentPreCheckin = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.PRE_CHECKIN}/${appointmentId}`);
  };
  const postBikeInitatePrToken = (params) => {
    return api.post(`${API_ENDPOINTS.BIKE_INITIATE_PR_TOKEN}`, params);
  };
  const getBikeAPIRAList = () => {
    return api.get(`${API_ENDPOINTS.BIKE_API_RA_LST}?timestamp=0`);
  };
  const getBikePickupRequest = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.BIKE_PICKUP_REQUEST}/${appointmentId}`);
  };
  const postBikePickupRequest = (params) => {
    return api.post(`${API_ENDPOINTS.BIKE_PICKUP_REQUEST}`, params);
  };
  const getCities = (param) => {
    return api.get(`${API_ENDPOINTS.BIKE_PICKUP_REQUEST}?dataType=city&query=${param}`);
  };
  const getBikePickupDatetimeSlots = () => {
    return api.get(`${API_ENDPOINTS.BIKE_PICKUP_REQUEST}?dataType=slot`);
  };
  const postBikeUploadDocument = (params) => {
    return api.post(`${API_ENDPOINTS.BIKE_UPLOAD_DOCUMENT}`, params, { headers: { 'Content-Type': 'multipart/form-data' } });
  };
  const postResendPrice = (params) => {
    return api.post(`${API_ENDPOINTS.RESEND_PRICE}`, params);
  };
  const getCarCJSlots = () => {
    return api.get(`${API_ENDPOINTS.CAR_CJ_SLOTS}`);
  };
  const getTPConsentletter = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.GET_CONSENT_LETTER_DOC}/${appointmentId}`);
  };
  const sendPriceSMSBike = (params) => {
    return api.post(`${API_ENDPOINTS.BIKE_SEND_PRICE_SMS}`, params);
  };
  const sendPickyAssistChat = (params) => {
    return api.post(`${API_ENDPOINTS.SEND_PICKY_ASSIST_CHAT}`, params);
  };
  const getCheckAndAllowPR = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.CHECK_AND_ALLOW_PR}/${appointmentId}`);
  };
  const postOnSpotRedirectionUrl = (params) => {
    return api.post(`${API_ENDPOINTS.ON_SPOT_REDIRECTION}`, params);
  };
  const getSellOnlineTimeSlot = (appointment) => {
    return api.get(`${API_ENDPOINTS.GET_SLOT_ONLINE}/${appointment}`);
  };
  const postBikePurchaseRequest = (params) => {
    return api.post(`${API_ENDPOINTS.BIKE_PURCHASE_REQUEST}`, params);
  };
  const getResourceAllocationStatus = () => {
    const selectedStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES);
    const qsParam = selectedStores ? `?type=cj&stores=${selectedStores}` : '';
    return api.get(`${API_ENDPOINTS.RESOURCE_CJ_ALLOCATION_STATUS}${qsParam}`);
  };
  const getCJMonitoring = () => {
    const selectedStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES);
    const qsParam = selectedStores ? `&stores=${selectedStores}` : '';
    return api.get(`${API_ENDPOINTS.RESOUCE_CJ_MONITORING}${qsParam}`);
  };
  const getDIYInspectionReport = (appointmentId) => {
    return api.get(`${API_ENDPOINTS.INSPECTION_REPORT}?appointmentId=${appointmentId}`);
  };
  const getDocumentStatusData = (appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`document-status/${appointmentId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getOCBDetails = (appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`ocb-request?appointmentId=${appointmentId}&bought=1`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const sendInspectionSMS = (appointmentId) => {
    const payload = {"appointmentId": appointmentId,"type":"DIY_INSPECTION"};
    return new Promise((resolve, reject) => {
      api.post(`/api/notification`, payload).then(response => {
        resolve(response.data);
      })
        .catch(error => {
          reject(error);
        });
    });
  };
    
  const submitAppointmentCancelReason = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`cancel-appointment`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
    
  const getAppointmentCancelReasons = () => {
    return new Promise((resolve, reject) => {
      api.get(`cancel-appointment?type=reason`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
    
  const confirmAppointment = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`confirm-appointment`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  const uploadCSV = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`upload/retail/category`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getAppointmentMarginQuota = (appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`/margin/quota/${appointmentId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
    
  const updateSaleOnlineQuote = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`update-offer-price`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const verifyBid = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`bid/update`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getSOPriceBreakup = (appointmentToken) => {
    return new Promise((resolve, reject) => {
      api.get(`/api/seller-price/${appointmentToken}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
    
  const getMissedCallData = (diy) => {
    const storeOrUid = getStoreOrUidQuery();
    const isPll = isPllUser();
    const qsParam = `${isPll ? 'isPll=true': ''}${diy? 'diy=1': ''}${storeOrUid}`;
    return new Promise((resolve, reject) => {
      api.get(`data-count?type=missed&${qsParam}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getPLLRAList = (storeId) => {
	const pllString = getAllocateIsPllString();
    return new Promise((resolve, reject) => {
      api.get(`pll-associate-list?storeId=${storeId}${pllString ? `&${pllString}`: ''}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const uploadQuotaFPCSV = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`upload/quota`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getStoreListV2 = (stores) => {
	const pllString = getAllocateIsPllString();
    return new Promise((resolve, reject) => {
      api.get(`/${API_ENDPOINTS.STORE_LIST}?countPllLead=1${pllString ? `&${pllString}`: ''}${stores ? `&stores=${stores}`: ''}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getLoginUserPermissions = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`/get-user-permission`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  const getStoreStartsWith = (query) => {
    return api.get(`/${API_ENDPOINTS.STORE_LIST}?search=${query}`);
  };

  const callCustomer = (appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`/click-to-call/${appointmentId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  	const getPriceDiffData = (appointmentId) => {
		return new Promise((resolve, reject) => {
			api.get(`price-diff-report?appointmentId=${appointmentId}`)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};
	const UpdatePriceDiffReportView = (appointmentId) => {
		const params = {
			"appointmentId": appointmentId
		};
		return new Promise((resolve, reject) => {
			api.post(`price-diff-report`, params)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};

  const ApprovePaymentPriceQuote = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`leadmon`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const RejectPaymentPriceQuote = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`leadmon`, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  
	const getZendeskTickets = () => {
		const {email=''} =  getLoginData();
		return new Promise((resolve, reject) => {
			api.get(`cc/ticket?raEmail=${email}`)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};

	const getZendeskTicketComments = (id) => {
		return new Promise((resolve, reject) => {
			api.get(`cc/ticket/${id}`)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};

	const updateZendeskTicketComment = (id, comment) => {
		const params = {
			"ticketId": id,
			"comment": comment
		};
		return new Promise((resolve, reject) => {
			api.post(`cc/ticket`, params)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};

  const stopOcb = (carId, appointmentId, auctionId) => {
		const params = {
			"carId": carId,
      "appointmentId": appointmentId,
      "auctionId":auctionId,
      "request_type": "AUCTION_OVER"
		};
		return new Promise((resolve, reject) => {
			api.post(`ocb-request`, params)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};

  const getUnallocatedLeads = (props) => {
    const {page} = props;
    const storeOrUid = getStoreOrUidQuery();
    const leadType = JSON?.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)??'')?.tab?.toUpperCase();
    const finalLeadType = leadType=='RT'?'RETARGET':leadType;
    return api.get(`/unallocated/lead?start=${page}&limit=${NUMBERS.TEN}${storeOrUid}${leadType? `&leadType=${finalLeadType}`:''}`);
	};

    return {
		updateZendeskTicketComment,
		getZendeskTicketComments,
		getZendeskTickets,
		getPriceDiffData,
		UpdatePriceDiffReportView,
        callCustomer,
        getStoreStartsWith,
        getLoginUserPermissions,
        getStoreListV2,
        uploadQuotaFPCSV,
        getRTDIYNegotiations,
        getRTNegotiations,
        getSOPriceBreakup,
        getMissedCallData,
        getPLLRAList,
        getAppointmentMarginQuota,
        updateSaleOnlineQuote,
        uploadCSV,
        verifyBid,
        getOCBDetails,
        sendInspectionSMS,
        confirmAppointment,
        submitAppointmentCancelReason,
        getAppointmentCancelReasons,
        getDocumentStatusData,
        getDIYInspectionReport,
        scheduleRI,
        getDiyNegotiations,
        getStoreRAList,
        getCJMonitoring,
        getResourceAllocationStatus,
        postBikePurchaseRequest,
        getSellOnlineTimeSlot,
        sendPriceSMSBike,
        postOnSpotRedirectionUrl,
        getCheckAndAllowPR,
        getBikeInitiatePRToken,
        getCarCJSlots,
        getTPConsentletter,
        getBikePickupDatetimeSlots,
        getCities,
        postBikeUploadDocument,
        postResendPrice,
        getBikeAPIRAList,
        getFetchLocation,
        postRescheduleAppointment,
        getAppointmentAnnouncement,
        postAppointmentAnnouncement,
        getAppointments,
        getNegotiations,
        getCheckInStatus,
        checkInAppointment,
        postFollowUp,
        getFollowUpHistory,
        getFollowUpDispositions,
        getCancellationReasons,
        denyCheckIn,
        getAppointmentCounts,
        getCarData,
        getInspectionReport,
        updateCustomerExpectedPrice,
        preCheck,
        paiRequest,
        sendOtp,
        verifyOtp,
        updateSuggestedQuote,
        globalSearch,
        getAppointmentStateJourney,
        getLeadDetails,
        getCallLogs,
        updateAppointment,
        getRecordingUrl,
        getLostLeads,
        getCheckInLeads,
        getVirtualNo,
        getRetailAssociates,
        getStoreList,
        getStoreAssociateList,
        getDepreciationData,
        getPllRaAppointmentList,
        allocateAppointment,
        getPllRaList,
        raiseOcbData,
        updateOcbData,
        getEvaluatorTokens,
        getRetailView,
        getmake,
        getModel,
        getYear,
        getState,
        getOdometer,
        getAwareness,
        getCity,
        getBranch,
        getSlots,
        bookAppointment,
        marketingQuestions,
        submitMarketingQuestions,
        getEvaluatorView,
        acceptOcbQuote,
        getOffer,
        LostLeadQuestions,
        submitLostLead,
        getSimilarCarsDetails,
        getCustomerCharges,
        getCustomerOfferDetails,
        getSimilarTransactedCars,
        getValidateDashboard,
        verifyWaitTimeOtp,
        sendWaitTimeOtp,
        getC2CBoundyValues,
        c2cList,
        MarkAppointmentStar,
        GetOrderDetail,
        getHomeInspectionZones,
        getEvaluatorList,
        assignEvaluator,
        assignBikeEvaluator,
        getOwnerShipType,
        postTokenElite,
        getDealerDetail,
        connectToDealer,
        getAppointedCjDetail,
        tokenEliteABTest,
        postDepReportViewedInfo,
        sendDepriciationReport,
        getRaList,
        getCjAllocationStatus,
        getTimeSlot,
        getEvaluatorLeadCountBasedOnAppointment,
        getEvaluatorLeadCountBasedOnStore,
        getChangeLogs,
        sendDepReportFeedBack,
        updateProfile,
        getLeadWithPhone,
        getVariant,
        storeCheckIn,
        getAppointmentsReview,
        submitReviewInspection,
        sendListCarOtp,
        sendInspectionReport,
        getAppointmentDocumentStatusDetails,
        postAppointmentBuyNowPrice,
        postStartAuction,
        postAppointmentReviseVN,
        getDashboardRAIncentive,
        getAdminRAId,
        postResendPickupOTP,
        getInvSimilarCars,
        sellerReviews,
        getPrEnable,
        getPrBankList,
        getPrVahanCheck,
        getPrKyc,
        savePrKyc,
        canclePrKyc,
        prResendPennySms,
        prResendChargesConsent,
        prDocumentView,
        prInitiateToken,
        getBikeInitiateTokenPayment,
        postBikeInitiateTokenPayment,
        getAppointmentPreCheckin,
        postBikeInitatePrToken,
        saveCepPrice,
        getBikePickupRequest,
        postBikePickupRequest,
        getAppointmentDetail,
        getPllAppointmentList,
        getStoreRaLeadMonList,
        ApprovePaymentPriceQuote,
        RejectPaymentPriceQuote,
        stopOcb,
        sendPickyAssistChat,
        getUnallocatedLeads,
    };
};