import React, { useRef, useEffect } from "react";
import styles from "./lead-appointments-detail.module.scss";
import H3 from "../shared/h3/component";
import LabelWithText from "../shared/label-with-text/component";
import { LEAD_DETAILS_TABS } from "../../utils/app-constants";
import ReasonDealLost from "../reason-deal-lost";
import { isBikeSource, getUid } from "../../utils/helper";
import { Link } from "react-router-dom";
import { getPortalHostUrl } from "../../utils/url-constants";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import moment from "moment";

const LEAD_APPOINTMENT = {
    DEAL_CREATED_DATE: "Deal Created Date",
    CURRENT_APPOINTMENT_DATE: "Current Appointment Date",
    ORIGINAL_APPT_DATE: "Original Appt Date",
    LINK_TO_RESCHEDULE: "Link to reschedule",
    IT_ITS_VERIFIED: "If its a verified",
    STORE_DETAILS: "Store Details",
    PR_AUTOMATION: "Background Check",
};

const LeadAppointmentsDetail = ({ selectedTab, data }) => {
    const appointmentDetailRef = useRef();
    const isBike = isBikeSource();

    const { appointmentId, isPRAutoAllowed, appointmentKey } = data;
    useEffect(() => {
        if (
            selectedTab &&
      selectedTab === LEAD_DETAILS_TABS.APPOINTMENT_DETAIL.key &&
      appointmentDetailRef &&
      appointmentDetailRef.current
        ) {
            appointmentDetailRef.current.scrollIntoView();
        }
    }, [selectedTab]);
    const onOpenPRLink = () => {
		trackLMSEvents("pr_automation_clicked", {
			eventLabel: appointmentId,
			lsRAID: getUid(),
			event: "pr_automation_clicked",
			eventCategory: "background_check",
			eventAction: "background_check",
			Timestamp: moment().format()
		});
        window.open(
            `${getPortalHostUrl()}/retail/lms/negoview/${appointmentKey}`,
            "_blank",
            "noopener"
        );
    };
    return (
        <div ref={appointmentDetailRef} className={styles.leadAppointment}>
            {isBike && <ReasonDealLost reason={data.lost_reason} />}
            <H3 text={LEAD_DETAILS_TABS.APPOINTMENT_DETAIL.label} />
            <div className={styles.textWrapper}>
                <div className={styles.detailsLeftWrapper}>
                    <LabelWithText
                        label={LEAD_APPOINTMENT.DEAL_CREATED_DATE}
                        text={data.deal_created_date}
                    />
                </div>
                <div className={styles.detailsRightWrapper}>
                    <LabelWithText
                        label={LEAD_APPOINTMENT.CURRENT_APPOINTMENT_DATE}
                        text={data.current_appt_date}
                    />
                </div>
            </div>
            <div className={styles.textWrapper}>
                <div className={styles.detailsLeftWrapper}>
                    <LabelWithText
                        label={LEAD_APPOINTMENT.ORIGINAL_APPT_DATE}
                        text={data.original_appt_date}
                    />
                </div>
                {!!data?.isMonetized && (
                    <div className={styles.detailsRightWrapper}>
                        <p className={styles.leadMon}>Lead Mon</p>
                    </div>
                )}
            </div>
            {!!isPRAutoAllowed && (
                <div className={styles.textWrapper}>
                    <div className={styles.detailsLeftWrapper}>
                        <LabelWithText label={LEAD_APPOINTMENT.PR_AUTOMATION} text="" />
                        <Link to="#" onClick={onOpenPRLink}>
                            {appointmentId}
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LeadAppointmentsDetail;
