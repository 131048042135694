/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./hi-customer-detail.module.scss";
import Nego_car from "../images/nego_car.svg";
import Nego_cale from "../images/nego_cale.svg";
import Nego_user from "../images/nego_user.svg";
import Nego_Phone from "../images/nego_phone.svg";
import { APPOINTMENT_TYPES_STRING, LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage, isBikeSource, openInNewTab } from "../../utils/helper";
import wa from './images/WhatsApp.svg';
import Link from "../link";
import { getPortalHostUrl } from "../../utils/url-constants";

const PORTAL_HOST_URL = getPortalHostUrl();

const HiCustomerDetail = ({ rowDetails, index, appointmentState, isPPRevamp =false }) => {
  const { model, variant, year, customerName, appointment, appointmentKey, virtualNumber, phoneNumber, ctaAccessFlag, 
    whatAppCTAFlag, retail_associate_email, whatAppCTAurl} = rowDetails || {};
  const isBike = isBikeSource();
  const [isWhatsAppIconVisible, setIsWhatsAppIconVisible] = useState(false);

  useEffect(()=>{
    const {originalRole, email} = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
        if (!!originalRole && originalRole.toLowerCase() === ROLE_KEYS.RA_TRA.toLowerCase() 
            && !!retail_associate_email && email === retail_associate_email 
            && !!whatAppCTAFlag) {
            setIsWhatsAppIconVisible(true);
        }
  },[rowDetails]);

  const onWhatsAppClick = () => {
    openInNewTab(whatAppCTAurl);
  };
  return (
    <div className={styles.carDetailWrapper}>
      {!isPPRevamp && <div className={styles.index}>{index}</div>}
      <div className={styles.carDetail}>
        <ul>
          <li>
            <div className={styles.detailsIcon}><img src={Nego_cale} alt="Nego_cale" /></div>
            <div className={styles.detailsInfo}>{isBike || !!ctaAccessFlag ? <p>{appointment}</p> : <a href={`/detail/${appointment}`}> {appointment}</a>}</div>
          </li>
          {!isPPRevamp && <li>
            <div className={styles.detailsIcon}><img src={Nego_user} alt="Nego_user" /></div>
            <div className={styles.detailsInfo}>{customerName}</div>
          </li>}
          {!isPPRevamp && <li>
            <div className={styles.detailsIcon}><img src={Nego_car} alt="Nego_car" /></div>
            <div className={styles.detailsInfo}><span className={styles.max}>{model}/{year}/{variant} </span></div>
          </li>}
          {(!isPPRevamp && appointmentState === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING && !!phoneNumber) &&
            <li>
              <div className={styles.detailsIcon}><img src={Nego_Phone} alt="Nego_car" /></div>
              <div className={styles.detailsInfo}><span className={styles.max}>{phoneNumber} </span></div>
            </li>
          }
          {(!isPPRevamp && appointmentState === APPOINTMENT_TYPES_STRING.PAYMENT_PROCESSING && !!virtualNumber) &&
            <li>
              <div className={styles.detailsIcon}><img src={Nego_Phone} alt="Nego_car" /></div>
              <div className={styles.detailsInfo}><span className={styles.max}>{virtualNumber} </span></div>
            </li>
          }
          {
            appointmentKey && 
            <li>
              <div className={styles.detailsIcon}><img src={Nego_cale} alt="Nego_cale" /></div>
              <div className={styles.detailsInfo}>
                <Link
                  href={`${PORTAL_HOST_URL}/retail/lms/negoview/${appointmentKey}`} 
                  preventDefault={true} 
                  text={"Nego View"} 
                  onClick={()=>{window.open(`${PORTAL_HOST_URL}/retail/lms/negoview/${appointmentKey}`,  "_blank", 'noopener');}}
                />
              </div>
            </li>
          }
          {!!isWhatsAppIconVisible && <li className={styles.whatsappchat}>
            <p className={styles.whatsappchat} onClick={onWhatsAppClick}><img src={wa} alt="view"/> Chat</p> 
          </li>}
        </ul>
      </div>
    </div>
  );
};

export default HiCustomerDetail;
